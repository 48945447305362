<template>
  <v-container class="pa-0" data-cy="ProductItemComponent">
    <v-card flat tile :elevation="0">
      <v-img
        class="product-image white--text align-end"
        :class="{ 'product-image-results': isResults }"
        :alt="`thumbnail for ${title}`"
        :src="thumbnail"
        :max-height="maxHeight"
        :aspect-ratio="aspectRatio"
      >
        <template v-if="enableLoyalty">
          <PointsRewardComponent
            @show="toggleModal"
            :points-value-text="pointsValue"
          />
          <PointsRewardModal @hide="toggleModal" :isModalOpen="isModalActive" />
        </template>
      </v-img>
    </v-card>

    <h3 class="truncate title-text size16-weight600 pb-1 pt-4">
      {{ title }}
    </h3>
    <p class="price-text mb-0">
      {{ $t('common.from') }}
      {{ priceValue | currency(priceCurrency) }}{{ $t('common.per_person') }}
    </p>
  </v-container>
</template>

<script>
import PointsRewardComponent from './PointsRewardComponent';
import PointsRewardModal from '@/modules/common/components/PointsRewardModal';

export default {
  name: 'ProductItemComponent',
  components: { PointsRewardComponent, PointsRewardModal },
  props: {
    title: String,
    thumbnail: String,
    pointsValue: Number,
    priceValue: Number,
    priceCurrency: String,
    maxHeight: {
      type: Number,
      default: 220,
    },
    aspectRatio: {
      type: Number || String,
      default: 1.5,
    },
    isResults: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return { isModalActive: false };
  },

  computed: {
    enableLoyalty() {
      return this.$store.getters['featureFlags/enableLoyalty'];
    },
  },

  methods: {
    toggleModal() {
      this.isModalActive = !this.isModalActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-image {
  height: 217px;

  &-results {
    height: 150px;
  }

  ::v-deep .v-image__image {
    border-radius: 8px;
  }
}

.title-text {
  color: #000;
}

.price-text {
  color: #000;
  font-size: 14px;
  font-weight: normal;
}

.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.amount {
  font-weight: bold;
}
</style>
