<template>
  <v-container class="pa-0">
    <template v-if="catalogGroups.isLoading">
      <v-row v-for="rowIndex in 3" :key="rowIndex + 10">
        <v-col v-for="colIndex in skeletonLoaderSize" :key="colIndex + 20">
          <v-skeleton-loader type="card" :loading="true" :tile="false" />
        </v-col>
      </v-row>
    </template>

    <template v-if="displayedCatalogs.length > 0">
      <DiscoveryListComponent />
      <v-row class="d-flex justify-center pt-12">
        <v-col cols="auto">
          <v-btn
            class="primary"
            depressed
            large
            data-cy="see-all-city-experiences"
            @click="onSeeAllCityExperiencesClick"
          >
            {{
              $t('discovery.see_all_city_experiences', {
                cityName: this.updatedCityName,
              })
            }}
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-row no-gutters class="mt-6">
        <v-col class="d-flex flex-column text-center mt-6 mt-md-12">
          <span class="title text-light-gray-color">
            {{ $t('no_results_found') }}
          </span>
          <span
            class="mt-2 subtitle-2 text-light-gray-color font-weight-bold search-subtitle"
          >
            {{ $t('update_keywords_show_more_experiences') }}
          </span>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import BookingSearchMixins from './mixins/BookingSearchMixins';
import DiscoveryPageMixins from './mixins/DiscoveryPageMixins';
import DiscoveryListComponent from './features/discovery-list/DiscoveryListComponent';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';

export default {
  name: 'CityDiscoveryPage',
  components: { DiscoveryListComponent },
  mixins: [
    GoogleTagManagerMixins,
    DiscoveryPageMixins,
    BookingSearchMixins,
    FlightsMixins,
  ],

  props: {
    flightIds: {
      type: Array,
      default: () => [],
    },

    flightNumber: {
      type: String,
      default: null,
    },

    flightDate: {
      type: String,
      default: null,
    },

    city: String,

    lng: [Number, String],

    lat: [Number, String],

    hasErrorInLoadingFlight: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (!this.hasPreviousResults) {
      this.loadCatalogGroups({
        flightIds:
          !!!this.flightNumber && !!!this.flightDate ? null : this.flightIds,
      });
    }
  },

  computed: {
    updatedCityName() {
      return this.city?.split(',')[0];
    },

    skeletonLoaderSize() {
      let pageSize = 3;
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 1;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        pageSize = 5;
      }
      return pageSize;
    },

    hasPreviousResults() {
      return (
        this.hasCatalogGroups &&
        this.cityName === this.savedCityName &&
        +this.latitude === +this.savedLatitude &&
        +this.longitude === +this.savedLongitude
      );
    },
  },

  watch: {
    flightIds(val) {
      this.loadCatalogGroups({
        flightIds: val,
      });
    },

    hasErrorInLoadingFlight(val) {
      if (val) {
        this.loadCatalogGroups({
          flightIds: null,
        });
      }
    },

    city(val, oldVal) {
      if (val != oldVal) {
        this.loadCatalogGroups({
          flightIds: null,
        });
      }
    },

    lng(val, oldVal) {
      if (val != oldVal) {
        this.loadCatalogGroups({
          flightIds: null,
        });
      }
    },

    lat(val, oldVal) {
      if (val != oldVal) {
        this.loadCatalogGroups({
          flightIds: null,
        });
      }
    },

    hasContextFlights(val, oldVal) {
      if (!val) {
        this.$router.push({ path: '', name: 'discoverNoFlight' });
      }
    },
  },

  methods: {
    onSeeAllCityExperiencesClick() {
      this.pushButtonClick('See All Experiences', this.cityName);
      if (
        this.$route.query &&
        this.$route.query.city &&
        this.$route.query.lat &&
        this.$route.query.lng
      ) {
        this.$router.push({
          name: 'discoverySearchResults',
          query: {
            city: this.$route.query.city,
            lat: this.$route.query.lat,
            lng: this.$route.query.lng,
          },
        });
      } else {
        this.$router.push({
          name: 'discoverySearchResults',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .text-light-gray-color {
  color: #857f72;
}
</style>
