<template>
  <v-row v-if="isFeaturedWebItemCategory" no-gutters class="mx-n3">
    <CategoryComponent
      :catalogType="catalog.type"
      :isFeatured="catalog.featured"
      :title="catalog.title"
      :items="catalog.items"
      :seeAllQueryParams="getSeeAllSearchParams(catalog)"
      displayPage="displayPage"
      showSeeAllBtn
    />
  </v-row>
</template>

<script>
import CategoryComponent from './CategoryComponent';

export default {
  name: 'CategoryGroupContainer',
  components: {
    CategoryComponent,
  },
  props: {
    catalog: {
      type: Object,
    },
    displayPage: {
      type: String,
      default: '',
    },
    showSeeAllBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFeaturedWebItemCategory() {
      return this.catalog.type == 'Item';
    },
  },
  methods: {
    getSeeAllSearchParams(catalog) {
      return !this.showSeeAllBtn
        ? null
        : !!catalog.groupSearch
        ? catalog.groupSearch.searchParams
        : null;
    },
  },
};
</script>
