<template>
  <router-link
    class="link primary--text font-weight-bold"
    :to="{ name: 'booking-details', params: { id: item.id } }"
  >
    <ProductItemComponent
      :title="item.title"
      :thumbnail="item.thumbnail"
      :pointsValue="loyaltyPointsValue"
      :priceValue="priceValue"
      :priceCurrency="baseCurrency"
      :aspectRatio="dynamicAspectRatio"
    ></ProductItemComponent>
  </router-link>
</template>

<script>
import ProductItemComponent from './ProductItemComponent';

export default {
  name: 'ProductItemContainer',
  props: ['item', 'dynamicAspectRatio'],
  components: { ProductItemComponent },
  computed: {
    loyaltyPointsValue() {
      if (this.item && this.item.minimumEarningValue) {
        return this.item.minimumEarningValue.value;
      } else return null;
    },
    priceValue() {
      if (this.item && this.item.priceStartingAt) {
        return this.item.priceStartingAt.value;
      } else return null;
    },
    baseCurrency() {
      if (this.item && this.item.priceStartingAt) {
        return this.item.priceStartingAt.baseCurrency;
      } else return null;
    },
  },
};
</script>

<style></style>
