<template>
  <v-container class="py-0 px-md-0">
    <template v-if="catalogGroups.isLoading">
      <v-row v-for="rowIndex in 3" :key="rowIndex + 10">
        <v-col v-for="colIndex in skeletonLoaderSize" :key="colIndex + 20">
          <v-skeleton-loader type="card" :loading="true" :tile="false" />
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-row no-gutters>
        <v-col
          cols="12"
          v-for="(catalog, index) in catalogsWithItems"
          :key="index"
          class="discovery-category"
        >
          <template v-if="isFeaturedWebItemCategory(catalog)">
            <CategoryGroupContainer
              :catalog="catalog"
              displayPage="Discovery Page"
              showSeeAllBtn
            />
          </template>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import DiscoveryPageMixins from '../../mixins/DiscoveryPageMixins.vue';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import CategoryGroupContainer from './CategoryGroupContainer';

export default {
  name: 'discovery-list',
  mixins: [DiscoveryPageMixins, GoogleTagManagerMixins],
  components: {
    CategoryGroupContainer,
  },

  props: {
    catalogs: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
  },

  computed: {
    catalogsWithItems() {
      return this.displayedCatalogs.filter(
        (item) => item.items && item.items[0].id
      );
    },
  },

  watch: {
    displayedCatalogs(val) {
      if (val && !!val.length) {
        this.pushProductImpressions(val);
      }
    },
  },

  methods: {
    isFeaturedWebItemCategory(catalog) {
      return catalog.type == 'Item';
    },
  },
};
</script>

<style lang="scss" scoped>
.discovery-category {
  margin-top: 84px;
}
</style>
