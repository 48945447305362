<template>
  <v-card elevation="0" color="#FFFFFF" class="card-border">
    <v-row class="pa-2">
      <v-col class="col-12 col-sm-6 pa-0">
        <v-img
          :alt="title"
          :src="imageUrl"
          :min-height="219"
          :aspect-ratio="imageAspectRadio"
          class="card-img"
        />
      </v-col>
      <v-col
        class="col-12 col-sm-6 info-card d-flex flex-column align-start pa-0"
      >
        <v-card-title
          :class="{ 'card-title--small': small }"
          class="card-title word-break"
        >
          {{ title }}
        </v-card-title>
        <v-card-text
          v-if="hasSubtitle"
          :class="{ 'card-text--small': small }"
          class="card-text px-6"
        >
          {{ subTitle }}
        </v-card-text>
        <v-card-actions
          :class="{ 'card-actions--small': small }"
          class="card-actions mt-auto pb-0 full-width"
        >
          <v-btn outlined block :href="linkUrl" target="_blank">
            {{ $t('discovery.visit_site') }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: 'web-item',
  props: {
    title: String,
    subTitle: String,
    imageUrl: String,
    linkUrl: String,
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasSubtitle() {
      return !!this.subTitle;
    },
    imageAspectRadio() {
      let ratio = 2.55;
      if (this.$vuetify.breakpoint.smAndDown) {
        ratio = 2.64;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        ratio = 1.88;
      }
      return ratio;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  padding: 12px;
  @include font-size(24, 32, 600);

  &--small {
    @include font-size(18, 26, 600);
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 8px 12px;
    @include font-size(24, 36, 600);
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding-left: 0;
    @include font-size(20, 24, 600);
  }
}

.card-text {
  @include font-size(20, 26);

  &--small {
    @include font-size(14, 20);
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    @include font-size(16, 24);
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    @include font-size(14, 20);
  }
}

.card-actions {
  @include font-size(18, 26, 600);

  &--small {
    padding: 0 12px;
    @include font-size(16, 24, 600);
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 0 12px;
    @include font-size(18, 26, 600);
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding-left: 0;
    @include font-size(16, 24, 600);
  }
}
.card-img {
  border-radius: 4px 0 0 4px;
  max-height: 348px;
  @media (max-width: map-get($grid-breakpoints, lg)) {
    max-height: 246px;
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    max-height: 219px;
    border-radius: 0;
  }
}
.text-decoration-none {
  text-decoration: none;
}
.card-border {
  border: 1px solid #e4e8ef !important;
  box-shadow: 0px 3px 8px rgba(88, 98, 113, 0.15) !important;
  border-radius: 4px;
  padding-left: 12px;
}

.word-break {
  word-break: normal;
}
</style>
