var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"z-position py-0",attrs:{"fluid":"","data-cy":"CategoryComponent"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"category",attrs:{"cols":"12"}},[_c('span',{staticClass:"category-name size20-weight400"},[_vm._v(" "+_vm._s(_vm.title)+" "+_vm._s(_vm.$route.query.city ? _vm.$t('common.in') + ' ' + _vm.$route.query.city : '')+" ")]),(_vm.shouldShowSeeAllBtn)?[_c('router-link',{staticClass:"category-link",attrs:{"to":{
            name: 'discoveryCategoryResults',
            query: {
              city: _vm.getCityName,
              lat: _vm.getCityLat,
              lng: _vm.getCityLng,
              title: _vm.title,
              'sub-categories': _vm.seeAllParamSubCategories,
              'start-date': _vm.seeAllParamAvailabilityStart,
              'end-date': _vm.seeAllParamAvailabilityEnd,
            },
          }},nativeOn:{"click":function($event){return _vm.pushSeeMoreExperiencesAnalytics(_vm.getCityName, _vm.title)}}},[_vm._v(" "+_vm._s(_vm.$t('common.see_more'))+" ")])]:_vm._e()],2)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('CarouselComponent',{attrs:{"items":_vm.items,"displayPage":_vm.displayPage,"title":_vm.title,"catalogType":_vm.catalogType}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }