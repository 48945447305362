<template>
  <v-container fluid class="z-position py-0" data-cy="CategoryComponent">
    <v-row no-gutters>
      <v-col cols="12" class="category">
        <span class="category-name size20-weight400">
          {{ title }}
          {{
            $route.query.city ? $t('common.in') + ' ' + $route.query.city : ''
          }}
        </span>
        <template v-if="shouldShowSeeAllBtn">
          <router-link
            class="category-link"
            @click.native="pushSeeMoreExperiencesAnalytics(getCityName, title)"
            :to="{
              name: 'discoveryCategoryResults',
              query: {
                city: getCityName,
                lat: getCityLat,
                lng: getCityLng,
                title: title,
                'sub-categories': seeAllParamSubCategories,
                'start-date': seeAllParamAvailabilityStart,
                'end-date': seeAllParamAvailabilityEnd,
              },
            }"
          >
            {{ $t('common.see_more') }}
          </router-link>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pa-0">
        <CarouselComponent
          :items="items"
          :displayPage="displayPage"
          :title="title"
          :catalogType="catalogType"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from 'moment';
import CarouselComponent from './CarouselComponent';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
export default {
  name: 'category',
  components: { CarouselComponent },
  mixins: [GoogleTagManagerMixins, SelectedContextFlightMixins, FlightsMixins],
  props: {
    displayPage: String,
    title: String,
    catalogType: {
      type: String,
      default: 'Item',
      validator: (value) =>
        ['Item', 'WebItem', 'QueryItem'].indexOf(value) !== -1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    seeAllQueryParams: {
      type: Object,
      default: () => {},
    },
    showSeeAllBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getCityName() {
      return !!this.seeAllQueryParams ? this.seeAllQueryParams.city : '';
    },
    getCityLat() {
      return !!this.seeAllQueryParams ? this.seeAllQueryParams.latitude : '';
    },
    getCityLng() {
      return !!this.seeAllQueryParams ? this.seeAllQueryParams.longitude : '';
    },
    shouldShowSeeAllBtn() {
      return (
        this.showSeeAllBtn &&
        !!this.seeAllParamSubCategories &&
        !!this.seeAllParamAvailabilityStart &&
        !!this.seeAllParamAvailabilityEnd
      );
    },
    seeAllParamSubCategories() {
      return !!this.seeAllQueryParams &&
        !!this.seeAllQueryParams.subCategories &&
        !!this.seeAllQueryParams.subCategories.length
        ? this.seeAllQueryParams.subCategories
        : null;
    },
    seeAllParamAvailabilityStart() {
      if (this.hasArrived || !this.hasSelectedContextFlight) {
        return moment().format('YYYY-MM-DD');
      } else {
        return moment(this.departureTime).format('YYYY-MM-DD');
      }
    },
    seeAllParamAvailabilityEnd() {
      if (this.hasArrived || !this.hasSelectedContextFlight) {
        return moment()
          .add(30, 'd')
          .format('YYYY-MM-DD');
      } else {
        return moment(this.departureTime)
          .add(30, 'd')
          .format('YYYY-MM-DD');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.z-position {
  z-index: 1;
}

.category {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 32px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    flex-direction: row;
  }

  &-name {
    color: var(--v-grey8-base);
  }

  &-link {
    color: var(--v-secondary-base);
    @include font-size(16, 150);
    text-decoration: none;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      margin-left: 20px;
    }
  }
}
</style>
